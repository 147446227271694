.sticky-button {
    position: fixed;
    bottom: 0%;
    z-index: 999;
    width: 100%;
    height: 64px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 3px solid white;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    line-height: 24px;
    cursor: pointer;
    opacity: 1;
    background-color: #2A7AF5;
    font-size: 20px;
    font-family: Baloo;
    color: white;
    transform: translateY(50px);
    transition: all 0.5s;
}

.sticky-button:hover {
    background-color: white;
    color: #2A7AF5;
    border-top: 3px solid #2A7AF5;
}

.sticky-button.visible {
    visibility: visible;
    transform: translateY(0);
}

.sticky-button.hidden {
    visibility: hidden;
    transform: translateY(64px);
}

@media only screen and (min-width: 768px) {
    .sticky-button {
        display: none;
    }
}