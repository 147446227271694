/******** NAV *******/
.header {
    background-color: #FEF7EA;
    padding-top: 20px;
    padding-bottom: 65px;
}

.navbar {
    position: fixed;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 30px);
    height: 63px;
    margin-left: 15px;
    margin-right: 15px;
    background: rgba(254, 247, 234, 0.85);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
}

.nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}

.nav-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
}

.nav-title {
    font-family: 'Tabardo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-right: 44px;
    cursor: pointer;
    text-transform: uppercase;
}

.nav-download-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 55px;
    background: #2A7AF5;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    display: none;
    transition: all ease-in 0.2s;
    /*Text*/
    font-family: Anke;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
}

.nav-download-app-btn:hover {
    background: #FFFFFF;
    border: 2px solid #2A7AF5;
    color: #2A7AF5;
}

.nav-collaborate-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 163px;
    height: 36px;
    background: #FFFFFF;
    border: 2px solid #2A7AF5;
    border-radius: 10px;
    transition: all ease-in 0.2s;
    /*Text*/
    font-family: Anke;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #2A7AF5;
    cursor: pointer;
}

.nav-collaborate-btn:hover {
    background: #2A7AF5;
    color: #FFFFFF;
}

.nav-link {
    font-family: Anke;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-left: 38px;
    display: none;
}


/******* MODAL ******/
.modal-wrapper {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 730px;
    padding: 35px 100px;
    background: #2A7AF5;
    border-radius: 26.0095px;
}

.modal-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.modal-wrapper .store-button {
    margin-top: 0
}

.modal-text {
    padding-top: 45px;
    padding-bottom: 17px;
    font-family: 'Baloo';
    font-size: 26px;
    line-height: 41px;
    color: #FFFFFF;
}

.modal-qrcode {
    width: 180px;
    height: 180px;
}

/*Tablet */
@media only screen and (min-width: 768px) {
    .navbar {
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }

    .nav-download-app-btn {
        display: block;
        width: 185px;
        height: 38px;
        font-size: 16px;
        padding: 0px 20px;
    }

    .nav-title {
        font-size: 20px;
        margin-right: 23px;
    }

    .nav-collaborate-btn {
        font-size: 16px;
        width: 182px;
        height: 38px;
    }
}

/* Laptop - Desktop */
@media only screen and (min-width: 1024px) {
    .header {
        padding-top: 30px;
        padding-bottom: 85px;
    }

    .navbar {
        width: calc(100% - 80px);
        height: 70px;
        margin-left: 40px;
        margin-right: 40px;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
    }

    .nav-left {
        padding-left: 40px;
    }

    .nav-right {
        padding-right: 40px;
    }

    .nav-title {
        font-size: 22px;
    }

    .nav-download-app-btn {
        width: 205px;
        height: 40px;
    }

    .nav-collaborate-btn {
        width: 220px;
        height: 40px;
    }

    .nav-link {
        display: block;
    }
}