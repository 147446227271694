/* RESPONSIVE MOBILE */

/****** FIRST SECTION *********/

.shopping-list_first_section {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shopping-list_main {
    padding-top: 1.875rem;
    background-color: #FAF9FF;
}

.button_store_container {
    cursor: pointer;
}

.shopping-list_main .button_store_container {
    cursor: pointer;
}

.shopping-list_title {
    font-size: 1.875rem;
    font-weight: 700;
    font-family: Anke;
    padding-bottom: 0.625rem;
    padding-left: 0.625rem;
}

.shopping-list_subtitle {
    font-family: Anke;
    font-size: 1.125rem;
    padding-bottom: 1.25rem;
    padding-left: 0.625rem;
}

.shopping-list_add_btn {
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2A7AF5;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.313rem;
    border: none;
    margin-bottom: 1.875rem;
    margin-left: 0.625rem;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    font-family: Anke;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.shopping-list_add_btn:hover {
    opacity: 0.8
}

/****** FLATLIST *********/
.shopping-list_flatlist_websites {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, 21.563rem);
    grid-gap: 2rem;
    justify-content: center;
    padding: initial;
}

.shopping-list_card {
    display: flex;
    width: 21.563rem;
    height: 8.75rem;
    background: #FDEFD4;
    border-radius: 0.625rem;
}

.shopping-list_card_img {
    width: 8.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
}

.shopping-list_card_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1.25rem;
}

.shopping-list_card_content_title {
    font-family: 'Anke';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: rgba(0, 0, 0, 0.8);
    padding-bottom: 0.625rem;
}

.shopping-list_card_content_name {
    font-family: 'Anke';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 1.25rem;
}

.shopping-list_card_content_share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    background: #FFFFFF;
    border: 1px solid #2A7AF5;
    border-radius: 1.25rem;
}

.shopping-list_card_content_share_text {
    font-family: 'Anke';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #2A7AF5;
}

/******** MODAL *******/
.shopping-list-modal-box {
    background: transparent;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.shopping-list_modal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30.5rem;
    height: 20.625rem;
    background-color: white;
    box-shadow: 0rem 0.625rem 1.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.938rem;
    padding: 3.125rem 1.875rem 3.125rem 1.875rem;
    margin: 0 auto;
}

.shopping-list_modal_title {
    font-family: Anke;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    padding-bottom: 2.5rem;
}

.shopping-list_modal_text {
    font-family: Anke;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    padding-bottom: 2.187rem;
}

.shopping-list_details_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.shopping-list_modal_container .mobile_link_button_container {
    display: flex;
    justify-content: space-between;
}

.shopping-list_modal_container .mobile_link_button_container .button_store_container {
    width: 45%;
    margin-top: 1rem;
}

.shopping-list_modal_container .mobile_link_button_container .button_store_container img {
    width: 100%;
    height: auto;
}

/* RESPONSIVE SMALL MOBILE */
@media screen and (max-width:550px) {
    .shopping-list_modal_container {
        width: 90%;
        height: 20.625rem;
        padding: 2rem 0.875rem 2rem 0.875rem;
    }

    .shopping-list_modal_container .mobile_link_button_container {
        display: flex;
        width: 100%;
    }

    .shopping-list_modal_container .button_store_container {
        margin-right: 0;
    }

    .shopping-list_modal_text {
        padding-bottom: 1rem;
    }

    .shopping-list_modal_title {
        font-size: 1.5rem;
        padding-bottom: 1.5rem;
        text-align: center;
    }
}

/* RESPONSIVE LAPTOP */
@media screen and (min-width:993px) {


    .shopping-list_main {
        padding-top: 3.438rem;
    }

    .shopping-list_first_section {
        padding-left: 0;
        padding-right: 0;
    }

    .shopping-list_title {
        padding-left: 0;
        font-size: 2.5rem;
    }

    .shopping-list_subtitle {
        padding-left: 0;
        font-size: 1.125rem;
    }

    .shopping-list_add_btn {
        width: 7.375rem;
        height: 3.438rem;
        font-size: 1.375rem;
    }

    .shopping-list_flatlist_websites {
        width: 80%;
        grid-template-columns: repeat(auto-fill, 21.563rem);
        justify-content: space-between;
        grid-gap: 2.5rem 1.25rem;
        margin-left: 5%;
        margin-right: 5%;
    }

    .shopping-list_details_container {
        padding-left: 5%;
        padding-right: 5%;
    }


    .shopping-list_modal_container {
        width: 37.5rem;
        height: 25.625rem;
    }

    .shopping-list_modal_title {
        font-size: 2.5rem;
    }

    .shopping-list_modal_text {
        font-size: 1.5rem;
        padding-bottom: 0;
    }


}