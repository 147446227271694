.rgpd_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 75px 0px 75px 0px; 
    background-color: #FEF7EA;
}

.rgpb_header_title {
    font-family: 'Baloo';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 41px;
    text-align: center;
    color: #312308;
}

.rgpd_container {
    padding: 1.5rem 1.875rem 1.5rem 1.875rem;
    background-color: white;
}

.rgpd_container h2 {
    padding-bottom: 1rem;
    padding-top: 1rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-family: "Helvetica"
}

.rgpd_container>h2:nth-child(12) {
    /* UTILISATION ET ACCES A APPLICATION */
    padding-bottom: 0;
}

.rgpd_container h3 {
    font-size: 1.1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.rgpd_container h4 {
    text-decoration: underline;
    font-weight: normal;
    padding-top: 1rem;
}

.rgpd_content>ul:nth-child(45) li {
    /* second list with circle dot */
    list-style-type: circle;
}

/* > Tablet - Laptop - Desktop */
@media only screen and (min-width: 768px) {
    .rgpd_header {
        padding: 40px;
    }
}