footer {
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    background-color: #FEF7EA;
}

.footer-wrapper {
    display: flex;
    flex-direction: column;
    padding: 48px 30px 100px 30px;
}

.footer-left {
    width: 100%;
}

.footer-right {
    width: 100%;
}

.footer-top {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.footer-social-media {
    margin-top: 25px;
}

.footer-parent-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146.56px;
    height: 51.42px;
    background: #FFFFFF;
    border-radius: 4.90247px;
    cursor: pointer;
}

.footer-title {
    font-family: 'Tabardo';
    font-weight: 400;
    font-size: 22px;
    color: #312308;
    text-transform: uppercase;
}

.footer-icon {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    color: #312308;
}

.footer-middle {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(189, 189, 189, 0.8);
}

.footer-col {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.footer-middle-title {
    font-family: 'Baloo';
    font-size: 18px;
    line-height: 28px;
    color: #312308;
    padding-bottom: 5px;
}

.footer-link {
    font-family: Anke;
    font-size: 16px;
    color: #312308;
    cursor: pointer;
}

.footer-bottom {
    display: flex;
    padding-top: 30px;
    justify-content: space-around;
}

.footer-nav-link {
    font-family: Anke;
    font-size: 14px;
    color: #707070;
    cursor: pointer;
}

/* Tablets and smaller laptops */
@media only screen and (min-width: 768px) {

    .footer-wrapper {
        padding: 73px 30px 40px 30px;
        flex-direction: row;
    }

    .footer-left {
        width: 35%;
    }

    .footer-right {
        width: 65%;
    }

    .footer-section {
        flex-direction: row;
    }

    .footer-social-media {
        margin-top: 32px;
    }

    .footer-icon {
        width: 40px;
        height: 40px;
        margin-right: 22px;
    }

    .footer-parent-title {
        width: 180.35px;
        height: 57.16px;
    }

    .footer-title {
        font-size: 28px;
    }

    .footer-middle {
        flex-direction: row;
        border: none;
    }

    .footer-middle-title {
        font-size: 20px;
        padding-bottom: 10px;
    }

    .footer-col:nth-child(1) {
        width: 130px;
        margin-right: 60px;
    }

    .footer-col {
        margin-right: 0px;
        width: 125px;
    }

    .footer-link {
        font-size: 18px;
    }

    .footer-bottom {
        margin-top: 70px;
        padding-top: 35.5px;
        border-top: 1px solid rgba(189, 189, 189, 0.8);
        justify-content: unset;
    }

    .footer-nav-link:nth-child(1),
    .footer-nav-link:nth-child(2) {
        margin-right: 60px;
    }

    .footer-nav-link:nth-child(1) {
        width: 130px;
    }

    .footer-nav-link {
        font-size: 18px;
    }

}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {
    .footer-wrapper {
        padding: 73px 100px 40px 100px;
        flex-direction: row;
    }

    .footer-col:nth-child(1) {
        width: 200px;
    }

    .footer-nav-link:nth-child(1) {
        width: 200px;
    }
}