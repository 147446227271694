.home-header-wrapper {
    background-color: #FEF7EA;
}

.intro-header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 0px 20px 0px;
}

.intro-left-content {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px 30px;
}

.intro-right-content {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.intro-right-content img {
    width: 100%;
    height: auto;
    border-radius: 40px;
}

.intro-header-title {
    font-family: 'Tabardo';
    font-size: 24px;
    color: #312308;
    padding-bottom: 20px;
}

.intro-header-span {
    background: #F5B12A;
    border-radius: 2.67407px;
    color: white;
    padding: 5px;
}

.intro-header-subtitle {
    font-family: 'Baloo';
    font-size: 16px;
    color: #312308;
}

.store-buttons-container {
    display: flex;
}

.store-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 51px;
    border-radius: 7.70183px;
    cursor: pointer;
    margin-top: 30px;
    margin-right: 20px;
}

.store-button img {
    width: 100%;
    height: auto;
}

.intro-bottom-text {
    font-family: 'Baloo';
    font-size: 14px;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;
}

/*Tablet */
@media only screen and (min-width: 768px) {

    .intro-header {
        flex-direction: row;
        justify-content: space-between;
        padding: 75px 0px 35px 30px;
    }

    .intro-left-content {
        width: 50%;
        padding: 0;
        margin-right: 20px;
        justify-content: center;
    }

    .intro-right-content {
        padding-top: 0;
        width: 50%;
    }

    .intro-header-title {
        padding-bottom: 25px;
        font-size: 30px;
    }

    .intro-header-subtitle {
        font-size: 20px;
    }

}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {

    .intro-header {
        padding: 22px 0px 30px 100px;
        max-width: 1441px;
        margin: 0 auto;
    }

    .intro-left-content {
        margin-right: 30px;
        justify-content: center;
        max-width: 520px;
    }

    .intro-right-content {
        max-width: 650px;
    }

    .intro-header-title {
        padding-bottom: 30px;
        font-size: 40px;
    }

    .store-button {
        width: 179.98px;
        height: 65.5px;
        margin-right: 25px;
    }


    .intro-bottom-text {
        font-size: 18px;
        padding-bottom: 40px;
    }
}


/*  Desktop & larger   */
@media only screen and (min-width: 1441px) {
    .intro-header {
        padding: 22px 100px 30px 100px;
    }
}


/****** SLIDER ******/
.slider-container {
    background-color: #FEF7EA;
    padding-bottom: 75px;
}

.slider-logo {
    display: flex;
    max-width: 1441px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 44px;
    padding-right: 44px;
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 1s ease, transform 0.5s ease, height 0.1s ease-in-out;
    height: auto;
}

.slider-logo.active {
    opacity: 1;
    transform: scale(1);
    padding-left: 44px;
    padding-right: 44px;
}

.slider-card {
    width: 33%;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* Meeko */
.slider-card:nth-child(4),
/*TranquileEmile */
.slider-card:nth-child(8) {
    display: none;
}

.slider-card img {
    border-radius: 5px;
    transition: all 0.5s ease 0s;
    max-width: 60%;
    height: auto;
}

/*Tablet */
@media only screen and (min-width: 768px) {
    .slider-container {
        padding-bottom: 87px;
    }

    .slider-logo {
        padding-left: 30px;
        padding-right: 30px;
    }

    .slider-logo.active {
        padding-left: 30px;
        padding-right: 30px;
    }

    .slider-card {
        width: 12.5%;
        padding: 1%;
        margin-bottom: 0px;
    }

    .slider-card img {
        max-width: 75%;
    }

    /* Meeko */
    .slider-card:nth-child(4),
    /*TranquileEmile */
    .slider-card:nth-child(8) {
        display: block;
    }
}


/* Desktop - laptops */
@media only screen and (min-width: 1024px) {
    .slider-container {
        padding-bottom: 135px;
    }

    .slider-logo {
        padding-left: 100px;
        padding-right: 100px;
    }

    .slider-logo.active {
        padding-left: 100px;
        padding-right: 100px;
    }
}

/****** GENERAL *****/
.center {
    text-align: center;
}

.main-title {
    font-family: 'Tabardo';
    font-size: 20px;
    text-align: center;
    color: #312308;
    padding-bottom: 20px;
}

.second-title {
    font-family: 'Tabardo';
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #312308;
    padding-bottom: 10px;
}

.text {
    font-family: Anke;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #312308;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .main-title {
        font-size: 24px;
    }

    .second-title {
        font-size: 18px;
        padding-bottom: 15px;
    }

}

/* Desktop - laptops */
@media only screen and (min-width: 1024px) {


    /****** GENERAL *******/
    .main-title {
        font-size: 28px;
        padding-bottom: 30px;
    }

    .second-title {
        font-size: 20px;
    }

    .text {
        font-size: 18px;
    }
}

/******* SECTION GREEN/ENVIRONMENTAL *****/

.environmentally-container {
    background-color: #FEF7EA;
}

.environmentally {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
}

.environmentally-header {
    padding-bottom: 50px;
}

.bluespan {
    background: #2A7AF5;
    border-radius: 4.90247px;
    padding: 5px;
    color: white;
}

.article-container {
    display: flex;
    flex-direction: column;

}

.environmentally-article {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.environmentally-article-first-container,
.environmentally-article-second-container,
.environmentally-article-third-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.environmentally-article img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

/* IMG n°1 nav filtering buttons */
.environmentally-article-first-container > img:nth-child(1) {
    box-shadow: 2.60802px 5.21604px 13.0401px 2.60802px rgba(0, 0, 0, 0.35);
    border-radius: 5.21604px;
    margin-bottom: 0;
}

/* IMG n°2 Tab partner */
.environmentally-article-second-container > img:nth-child(1) {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 60px;
}

/* IMG AWESOME */
.environmentally-article-second-container > img:nth-child(2) {
    position: absolute;
    bottom: -30px;
    right: -30px;
    width: 30%;
}

.environmentally-bottom-text {
    font-family: 'Soopafresh';
    font-size: 16px;
    text-align: center;
    color: #312308;
    padding-top: 0px;
}

/* Mobile */
@media only screen and (min-width: 671px) {
    .article-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .environmentally-article {
        width: 30%;
        padding-bottom: 0;
        justify-content: flex-start;
    }

    .environmentally-bottom-text {
        padding-top: 50px;
    }

}

/* Tablet */
@media only screen and (min-width: 768px) {

    /******* SECTION GREEN/ENVIRONMENTAL *****/
    .environmentally {
        padding-bottom: 60px;
    }

    .environmentally-bottom-text {
        font-size: 20px;
    }
}


/* Desktop - laptops */
@media only screen and (min-width: 1024px) {
    .environmentally {
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 75px;
        max-width: 1441px;
        margin: 0 auto;
    }

    .environmentally-header {
        padding-bottom: 70px;
    }

    .environmentally-bottom-text {
        padding-top: 70px;
        font-size: 26px;
    }

}

/* Desktop and larger laptops */
@media only screen and (min-width: 1441px) {
    .environmentally {
        max-width: 1441px;
        margin: 0 auto;
    }
}

/****** CategoriesBanner  *********/
.category-banner {
    background-color: #2a7af5;
    height: 57px;
    overflow: hidden;
    position: relative;
}

.category-names {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
}

.category-names span {
    margin: 0 15px;
    font-size: 16px;
    color: #fff;
    white-space: nowrap;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .category-banner {
        height: 63px;
    }

    .category-names span {
        font-size: 18px;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {
    .category-banner {
        height: 78px;
    }

    .category-names span {
        margin: 0 20px;
        font-size: 23px;
    }
}

/**** FindMyWayAmongGems ****/
.gems-container {
    padding-top: 75px;
    background-color: #FEF7EA;
}

.gems-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #3B1EFA;
    border-radius: 30px;
    padding: 35px 30px;
}

.gems-card-content {
    order: 2;
}

.whitespan {
    background: #FFFFFF;
    border-radius: 4.90247px;
    padding: 5px;
    color: black;
}

.gems-card-image {
    order: 1;
    margin-bottom: 50px;
}

.gems-card-image>span:nth-child(1) {
    text-align: center;
}

.gems-card-image img {
    max-width: 75%;
    height: auto;
    border: 3.45982px solid #FFFFFF;
    border-radius: 17.2991px;
}

.gems-card-text p {
    margin-bottom: 20px;
}

.gems-good-idea {
    display: flex;
    justify-content: center;
}

.gems-good-idea img {
    width: 120px;
    height: 117px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .gems-card {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 30px;
        margin-right: 30px;
        padding: 30px 30px;
    }

    .gems-card .main-title {
        position: absolute;
        top: 30px;
        padding-right: 30px;
    }

    .gems-card-content {
        padding-top: 100px;
        flex-basis: 50%;
        order: 1;
    }

    .gems-card-image {
        padding-top: 50px;
        flex-basis: 45%;
        order: 2;
    }
}

/* Desktop - laptops */
@media only screen and (min-width: 1024px) {

    .gems-container {
        padding-top: 120px;
    }

    .gems-card .main-title {
        position: relative;
        top: 0;
        padding-right: 0;
    }

    .gems-card {
        padding: 50px 40px;
        margin-left: 100px;
        margin-right: 100px;
    }

    .gems-card-image {
        padding-top: 0;
    }

    .gems-card-image img {
        border: 5px solid #FFFFFF;
        border-radius: 25px;
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .gems-card-image>span:nth-child(1) {
        text-align: right;
    }

    .gems-card-content {
        padding-top: 0;
    }

    .gems-good-idea img {
        width: 147px;
        height: 144px;
        margin-top: 30px;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1441px) {

    .gems-wrapp-card {
        max-width: 1441px;
        margin: 0 auto;
    }

}

/****** Stay Inform ****/
.stay-inform-container {
    background-color: #FEF7EA;
}

.stay-inform-card {
    display: flex;
    flex-direction: column;
    padding: 75px 30px 30px 30px;
    max-width: 1441px;
    margin: 0 auto;
}

.stay-inform-card-content {
    order: 1;
}

.stay-inform-card-text {
    display: flex;
    position: relative;
}

.stay-inform-card-text img {
    width: 70.65px;
    height: 66.9px;
    margin-left: 8px;
}

.second-text {
    font-family: 'Soopafresh';
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #312308;
    padding-top: 20px;
}

.stay-inform-card-image {
    order: 2;
    margin-top: 50px;
    text-align: center;
}

.stay-inform-card-image img {
    max-width: 75%;
    height: auto;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .stay-inform-card {
        flex-direction: row;
        justify-content: space-between;
        padding: 75px 30px 100px 30px;
    }

    .stay-inform-card-content {
        order: 2;
        flex-basis: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .stay-inform-card-image {
        order: 1;
        flex-basis: 40%;
        margin-top: 0px;
        text-align: left;
    }

    .second-text {
        font-size: 20px;
        line-height: 24px;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {
    .stay-inform-card {
        padding: 163px 100px 124px 100px;
    }

    .stay-inform-card-content {
        flex-basis: 55%;
    }

    .stay-inform-card-text img {
        width: 153.03px;
        height: 144.9px;
        margin-left: 30px;
    }

    .stay-inform-card-image {
        text-align: left;
    }

    .second-text {
        font-size: 26px;
        line-height: 32px;
    }

    .stay-inform-card-image {
        flex-basis: 40%;
        margin-top: 0px;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1441px) {
    .stay-inform-card {
        max-width: 1441px;
        margin: 0 auto;
    }

}

/***** RATING *****/
.rating-container {
    padding: 84px 0px 154px 0px;
    background: #2A7AF5;
}

.rating-container .main-title {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 22px;
}

.rating-slider-container {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-left: 30px;
    padding-right: 30px;
    gap: 20px;
}

.rating-slider {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}

.rating-card {
    position: relative;
    width: 264.95px;
    height: 366px;
    background: #FFFFFF;
    border-radius: 15.913px;
    padding: 20px;
}

.rating-stars {
    padding-bottom: 15px;
}

.rating-stars svg {
    width: 27.85px;
    height: 27.85px;
}

.rating-title {
    font-family: 'Baloo';
    font-size: 18px;
    padding-bottom: 23px;
    color: #000000;
}

.rating-text {
    font-family: Anke;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.rating-identity {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 20px;
}

.rating-identity-name {
    font-family: Anke;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding-left: 20px;
}

/* Tablet */
@media only screen and (min-width:768px) {
    .rating-container {
        padding: 67px 0px 100px 0px;
    }

    .rating-container .main-title {
        font-size: 28px;
    }

    .rating-slider {
        margin-top: 37px;
    }

    .rating-stars {
        padding-bottom: 20px;
    }

    .rating-stars svg {
        width: 35px;
        height: 35px;
    }

    .rating-title {
        font-size: 20px;
        padding-bottom: 30px;
    }
}

/* Adjust Slideable Card */
@media only screen and (min-width:900px) {
    .rating-slider-container {
        justify-content: center;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {

    .rating-container {
        padding: 112px 0px 127px 0px;
    }

    .rating-container .main-title {
        padding-left: 100px;
        padding-right: 100px;
        font-size: 35px;
    }

    .rating-slider-container {
        padding-left: 15px;
        padding-right: 15px;
        overflow-x: hidden;
    }

    .rating-slider {
        margin-top: 70px;

    }

    .rating-card {
        width: 300px;
        height: 430px;
        border-radius: 20px;
        padding: 25px;
    }



    .rating-title {
        font-size: 22px;
    }

    .rating-text {
        font-size: 18px;
        line-height: 21px;
    }

    .rating-identity {
        bottom: 25px;
    }

    .rating-identity-name {
        font-size: 18px;
        line-height: 21px;
        padding-left: 25px;
    }
}

@media only screen and (min-width: 1300px) {

    .rating-slider-container {
        justify-content: center;
        gap: 40px;
    }

    .rating-card {
        width: 333px;
        height: 460px;
        border-radius: 20px;
        padding: 25px;
    }

}

/******* INSTAGRAM ********/
.instagram-container {
    background-color: #FEF7EA;
}

.instagram-content {
    padding: 75px 30px 40px 30px;
}

.instagram-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instagram-header .main-title {
    font-size: 22px;
}

.orangespan {
    background: #E85D4A;
    border-radius: 4.90247px;
    padding: 5px;
    color: white;
}

.follow-us {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 42px;
    background: #E85D4A;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    /* Text */
    color: white;
    font-family: Anke;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
}

.follow-us:hover {
    background: white;
    color: #E85D4A;
    border: 1px solid #E85D4A;
}

.instagram-container .second-text {
    display: none;
}

.instagram-gallery {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.gallery-card {
    flex-basis: 47%;
}

.gallery-card img {
    width: 100%;
    height: auto;
}

/* Tablet */
@media only screen and (min-width: 768px) {

    .instagram-content {
        padding: 75px 30px 100px 30px;
    }

    .instagram-header {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    .instagram-header .main-title {
        padding-bottom: 35px;
        font-size: 28px;
        margin-right: 35px;
    }

    .follow-us {
        width: 144px;
        height: 47px;
    }

    .instagram-container .second-text {
        display: block;
        text-align: center;
        padding-top: 0;
    }

    .gallery-card {
        flex-basis: 22.8%;
    }

    .instagram-gallery {
        gap: 20px;
    }
}

/* Desktop and larger laptops */
@media only screen and (min-width: 1024px) {

    .instagram-content {
        padding: 110px 100px 40px 100px;
    }

    .instagram-header .main-title {
        padding-bottom: 0;
        font-size: 35px;
        margin-right: 40px;
    }

    .follow-us {
        width: 165px;
        height: 55px;
        /* Text */
        font-size: 18px;
    }

    .instagram-container .second-text {
        padding-top: 30px;
    }

    .gallery-card {
        flex-basis: 22%;
    }

    .instagram-gallery {
        gap: 30px
    }

}

/* Large desktops and high-resolution screens */
@media only screen and (min-width: 1441px) {

    .instagram-content {
        max-width: 1441px;
        margin: 0 auto;
    }

    .gallery-card {
        flex-basis: 23%;
    }
}

/****** Contact Form ******/
.contact-container {
    background-color: #fff;
}

.contact-content {
    padding: 75px 30px;
}

.contact-container .main-title {
    font-size: 22px;
}

.purplespan {
    background: #4D34E8;
    border-radius: 4.90247px;
    padding: 5px;
    color: white;
}

.contact-form {
    padding-top: 30px;
}

.contact-form-content {
    display: flex;
    flex-direction: column;
}

.contact-left-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-right-column {
    width: 45%;
    display: none;
}

.contact-right-column img {
    width: 100%;
    height: auto;
    border-radius: 15.465px;
    border: 3.30px solid #4D34E8;
}

.contact-form-row {
    display: flex;
    justify-content: space-between;
}

.contact-form-row input {
    width: 45%;
}

.contact-input {
    width: 100%;
    height: 57px;
    background: #FFFFFF;
    border: 2px solid #ABACAC;
    border-radius: 10px;
    /* Text */
    font-family: Anke;
    font-size: 16px;
    padding-left: 15px;
    color: black;
    margin-bottom: 25px;
}

.contact-left-column .contact-input-error {
    width: 100%;
    height: 57px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 2px solid red;
    /* Text */
    font-family: Anke;
    font-size: 16px;
    padding-left: 15px;
    color: black;
    margin-bottom: 25px;
}

.contact-submit-btn-desactivate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 42px;
    background: #DADADA;
    border-radius: 5px;
    margin-top: 20px;
}

.contact-submit-btn-desactivate p {
    font-family: Anke;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #6A6969;
    text-transform: uppercase;
    margin-right: 5px;
    letter-spacing: 2px;
}

.contact-submit-svg-desactivated {
    font-weight: 700;
    font-size: 16px;
    color: #6A6969;
}

.contact-submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 42px;
    background: #4D34E8;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.4s ease;
}

.contact-submit-btn:hover {
    opacity: 0.8;
}

.contact-submit-btn input {
    color: #fff;
    font-family: Anke;
    font-weight: 700;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-right: 5px;
    letter-spacing: 2px;
}

.contact-submit-svg {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
}

.contact-animation-sending-email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
}

.contact-form-send {
    font-size: 16px;
    font-family: Anke;
    font-weight: bold;
    text-align: center;
    color: #312308;
}

.contact-form-bounce {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

/* Tablet */
@media only screen and (min-width: 768px) {
    .contact-content {
        padding: 75px 30px 60px 30px;
    }

    .contact-container .main-title {
        font-size: 28px;
        padding-bottom: 30px;
    }

    .contact-form-content {
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-left-column {
        width: 45%;
        justify-content: center;
        position: relative;
    }

    .contact-right-column {
        display: block;
    }
}

/* Desktop - laptops */
@media only screen and (min-width: 1024px) {

    .contact-content {
        padding: 120px 100px 80px 100px;
    }

    .contact-container .main-title {
        font-size: 35px;
        padding-bottom: 20px;
    }

    .contact-form {
        padding-top: 30px;
    }

    .contact-form-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .contact-right-column img {
        border: 5px solid #4D34E8;
        border-radius: 23.3937px;
    }

    .contact-form-row input {
        width: 45%;
    }

    .contact-input {
        font-size: 18px;
        padding-left: 22px;
    }

    .contact-left-column .contact-input-error {
        font-size: 18px;
        padding-left: 22px;
    }

    .contact-submit-btn-desactivate {
        width: 179px;
        height: 55px;
    }

    .contact-submit-btn-desactivate p {
        font-size: 18px;
        line-height: 21px;
    }

    .contact-submit-svg-desactivated {
        font-size: 20px;
    }

    .contact-submit-btn {
        width: 179px;
        height: 55px;
    }

    .contact-submit-btn input {
        font-size: 18px;
    }

    .contact-submit-svg {
        font-size: 20px;
    }

    .contact-form-send {
        font-size: 18px;
    }

}

/* Desktop and larger laptops */
@media only screen and (min-width: 1441px) {

    .contact-content {
        max-width: 1441px;
        margin: 0 auto;
    }
}