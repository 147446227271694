/* Fonts import */
@font-face {
    font-family: 'Anke';
    src: url('./assets/fonts/Anke.otf');
}

@font-face {
    font-family: 'Baloo';
    src: url('./assets/fonts/Baloo.ttf');
}

@font-face {
    font-family: 'Soopafresh';
    src: url('./assets/fonts/Soopafresh.ttf');
}

@font-face {
    font-family: 'Tabardo';
    src: url('./assets/fonts/Tabardo.otf');
}

@font-face {
    font-family: 'Trebuchet';
    src: url('./assets/fonts/Trebuchet.ttf');
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}


/*Correct the line height in all browsers. Prevent adjustments of font size after orientation changes in iOS.*/
html {
    line-height: 1.5;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Set default font size and line height */
body {
    font-size: 1rem;
    line-height: 1.5;
}

/* Remove border on images */
img {
    border-style: none;
}

/* Reset anchor text decoration */
a {
    text-decoration: none;
}


/*Add the correct box sizing in Firefox,
and show the overflow in Edge and IE.*/
hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */
}

/* Reset focus input */
input:focus {
    outline: none;
    border: 1px solid #4D34E8;
}